import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button, { ButtonType } from '../../../components/shared/form-control/Button';
import { Input } from '../../../components/shared/form-control/Input';
import { PasswordInput } from '../../../components/shared/form-control/PasswordInput';
import { Heading, HeadingSize } from '../../../components/shared/text/Heading';
import { ToastType, useToasts } from '../../../contexts/ToastContext';
import AuthService from '../../../services/AuthService';
import BaseService from '../../../services/BaseService';
import StorageService from '../../../services/StorageService';
import { isEmailValid } from '../../../utils/EmailUtils';
import { FlowProps } from './LoginFlow';
import { useCurrentClient } from '../../../global-state/Clients';

const PasswordLogin: FC<FlowProps> = (props) => {
  const { next } = props;

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const toasts = useToasts();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation('auth');
  const setCurrentClient = useCurrentClient((x) => x.setValue);

  useEffect(() => {
    setCurrentClient(null);
  }, [setCurrentClient]);

  const doLogin = useCallback(() => {
    setLoading(true);
    //This function is called to get a token given username and password
    AuthService.login(username, password)
      .then((res) => {
        BaseService.recreateHttpClient(null);
        i18n.changeLanguage(res.data.user.language);
        StorageService.setLang(res.data.user.language);
        next(res.data.twoFactorRequired ? 'otp' : 'done');
      })
      .catch(() => {
        toasts.addToast({
          title: t('login.toasts.invalid.title'),
          type: ToastType.ERROR,
          description: t('login.toasts.invalid.description'),
          expiresInMs: 10000,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [i18n, next, password, t, toasts, username]);

  const valid = useMemo(() => !!username && !!password && isEmailValid(username), [password, username]);

  const detectSubmit = useCallback(
    (e: KeyboardEvent) => {
      if (valid && e.key === 'Enter') {
        doLogin();
      }
    },
    [doLogin, valid],
  );

  return (
    <>
      <Heading size={HeadingSize.H1}>{t('login.heading')}</Heading>
      <Heading size={HeadingSize.H2} actualSize={HeadingSize.H6}>
        {t('login.subheading')}
      </Heading>
      <div className="w-96">
        <Input
          autocomplete="email"
          name="email"
          id="email"
          autoFocus
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          placeholder={t('login.inputs.email')}
          label={t('login.inputs.email')}
          type="email"
          onKeyPress={detectSubmit}
          data-cy="login-email"
        />
        <PasswordInput
          autocomplete="password"
          name="password"
          id="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder={t('login.inputs.password')}
          label={t('login.inputs.password')}
          onKeyPress={detectSubmit}
          data-cy="login-password"
        />
        <div className="px-10 text-center">
          <Button
            className="mt-6 w-full"
            type={ButtonType.PRIMARY}
            onClick={doLogin}
            loading={loading}
            disabled={loading || !valid}
            data-cy="login-submit"
          >
            <span className="font-medium">{t('login.buttons.login')}</span>
          </Button>
          <div className="mt-4 text-center font-medium">
            <button
              className="cursor-pointer hover:underline"
              tabIndex={0}
              data-cy="forgot-password-link"
              onClick={() => navigate('/auth/forgot-password')}
            >
              {t('login.buttons.forgot-password')}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PasswordLogin;
